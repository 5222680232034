import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const StyledIndex = styled.div`
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HomeContent = styled.div`
  margin: 50px auto;
  min-width: 300px;
  color: white;
`;

export const LinksContainer = styled.div`
  ${({ theme }) => theme.flexHelpers.centerAll}
  margin-top: 48px;
  a,
  a:visited,
  a:hover,
  a:focus {
    text-decoration: none;
    color: white;
  }
`;

export const SignoutButton = styled(Button)`
  /* Same style as app tile */
  color: white;
  background: rgba(0, 0, 0, 0.4);
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const Header = styled.div`
  ${({ theme }) => theme.flexHelpers.centerAll}
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  .kh-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
  margin: 0 auto;
  margin-top: 17px;
  width: 80%;
`;
export const LoginText = styled.span`
  width: 100%;
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding: 0 0 ${({ theme }) => theme.spacing(2)};
  font-size: 12px;
  opacity: 0.8;
  color: ${({ theme }) => theme.palette.common.white};
`;
export const LoginButton = styled.button`
  ${({ theme }) => theme.flexHelpers.centerAll};
  background-color: ${({ theme }) => theme.appColors.krampRed};

  border-radius: 3px;
  width: 200px;
  height: 44px;
  border: none;
  overflow: hidden;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  &:active {
    opacity: 0.9;
  }

  img {
    max-height: 22px;
  }
`;
export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
`;

export const Version = styled.span`
  position: absolute;
  width: 100%;
  bottom: 4px;
  left: 0;
  font-size: 10px;
  text-align: center;
  opacity: 0.3;
  color: #fff;
`;
