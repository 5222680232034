import { useEffect, useState } from 'react';
import Head from 'next/head';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { useDc3_GetProfileLazyQuery } from '../compositions/gql/home.generated';
import {
  DC3_COUNTRY_CODE,
  DC3_LOCALE,
  DEVELOPMENT,
} from '@dc3/utils/constants';
import { createLoginUrl, mockLogin, useUserData } from '@dc3/utils/auth';
import type { Country } from '@dc3/utils/tenant-config';
import { useI18n } from '@dc3/utils/hooks';
import { getCountryByField } from '@dc3/utils/helpers';
import { HomeLayout, useMainNavigation } from '@dc3/ui/layout';
import { Logo } from '@dc3/ui/logo';
import { Text } from '@dc3/ui/text';
import { CountrySelector } from '@dc3/ui/form-fields';
import { AppTile } from '@dc3/ui/app-tile';
import {
  BackgroundImage,
  HomeContent,
  LinksContainer,
  LoginButton,
  SignoutButton,
  StyledIndex,
  Version,
} from '../styles/index.styles';
import { useHotkeys } from 'react-hotkeys-hook';
import { MenuItemInterface } from '@dc3/ui/menu';
import { Grow } from '@dc3/ui/grow';
import { Box } from '@dc3/ui/box';
import { Icon } from '@dc3/ui/icon';

export const Index = (props) => {
  const {
    publicRuntimeConfig: {
      TENANT,
      MEDIA_SERVER,
      SSO_LOGIN_URL,
      SSO_LOGOUT_URL,
      SSO_LOGIN_SIGURD_URL,
      SSO_LOGIN_REDIRECT_TO_URL,
      TAG_NAME,
      LOCAL_LOGIN_URL,
      MOCK_LOGIN_USERNAME,
      MOCK_LOGIN_PASSWORD,
    },
  } = getConfig();

  const [countryCode, setCountryCode] = useState(
    props.userData?.country?.code || null,
  );
  const translate = useI18n('home');
  const { userData, setCountry } = useUserData();
  const menuItems = useMainNavigation();

  // Used for fetching a default locale
  const [getProfile, { loading }] = useDc3_GetProfileLazyQuery({
    onCompleted(resp) {
      const profileSettings = resp?.profile?.settings;
      const countrySetting = profileSettings?.find(
        (setting) => setting.key === 'countryCode',
      );

      if (countrySetting?.value) {
        const country = getCountryByField('code', countrySetting.value);
        country && setLocaleCookies(country);
        setCountry(country);
        setCountryCode(country.code);
      }
    },
  });

  useEffect(() => {
    // fetch default locale on each login.
    if (userData && !countryCode) {
      getProfile();
    }
  }, [getProfile, userData, countryCode]);

  const setLocaleCookies = (country: Country) => {
    Cookies.set(DC3_COUNTRY_CODE, country.code);
    Cookies.set(DC3_LOCALE, country.locale);
  };

  const handleLogin = async () => {
    // This cookie is being hardcoded because we
    // don't have a country selector for Maykers but there
    // is a need to set a locale in order to make api requests
    if (TENANT === 'maykers') {
      Cookies.set(DC3_LOCALE, 'en_GB');
    }

    if (DEVELOPMENT) {
      await mockLogin({
        username: MOCK_LOGIN_USERNAME,
        password: MOCK_LOGIN_PASSWORD,
        loginUrl: LOCAL_LOGIN_URL,
        tenant: TENANT,
      });
      window.location.reload();
    } else {
      window.location.href = createLoginUrl(
        SSO_LOGIN_URL,
        SSO_LOGIN_REDIRECT_TO_URL,
        SSO_LOGIN_SIGURD_URL,
      );
    }
  };

  const onCountryChange = (country: Country) => {
    setLocaleCookies(country);
    window.location.reload();
  };

  const tiles = menuItems.filter((tile) => tile.enabled);
  const getHotkeys = (apps: MenuItemInterface[]) => {
    const hotkeys = [];
    for (let i = 0; i < apps.length; i++) {
      hotkeys.push((i + 1).toString());
    }
    return hotkeys.join(',');
  };
  const hotKeys = getHotkeys(menuItems);
  useHotkeys(hotKeys, (_, handler) => {
    switch (handler.key) {
      case '1':
        window.location.href = `${tiles[0].route}`;
        break;
      case '2':
        window.location.href = `${tiles[1].route}`;
        break;
      case '3':
        window.location.href = `${tiles[2].route}`;
        break;
      case '4':
        window.location.href = `${tiles[3].route}`;
        break;
      case '5':
        window.location.href = `${tiles[4].route}`;
        break;
      case '6':
        window.location.href = `${tiles[5].route}`;
        break;
      case '7':
        window.location.href = `${tiles[6].route}`;
        break;
      case '8':
        window.location.href = `${tiles[7].route}`;
        break;
      case '9':
        window.location.href = `${tiles[8].route}`;
        break;
      case '0':
        window.location.href = `/settings/`;
        break;
    }
  });

  return (
    <>
      <Head>
        <title>{translate('pageTitle')}</title>
      </Head>
      <HomeLayout>
        <StyledIndex>
          <HomeContent>
            <Grow in={true}>
              <Box component="header" textAlign="center" marginBottom={4}>
                <Logo
                  url={`${MEDIA_SERVER}/dark.svg`}
                  alt="DC3 Logo"
                  width={360}
                />
              </Box>
            </Grow>

            <Grow in={true}>
              <LinksContainer>
                {userData ? (
                  <div data-testid="home-menu">
                    {tiles.map(({ title, description, icon, route }, index) => (
                      <AppTile
                        key={index}
                        text={title}
                        description={description}
                        url={route}
                        dimension={115}
                        icon={icon}
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                    {/* <LoginText>
                      <Text color="initial" value="home.loginWith" />
                    </LoginText> */}

                    <LoginButton
                      onClick={handleLogin}
                      data-testid="login-button"
                    >
                      <img src={`${MEDIA_SERVER}/kramp.png`} alt="Kramp sso" />
                    </LoginButton>
                  </div>
                )}
              </LinksContainer>
            </Grow>
          </HomeContent>
        </StyledIndex>

        {userData && (
          <Box
            color="white"
            display="flex"
            width="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            bottom="32px"
          >
            <CountrySelector
              isLoading={loading}
              value={countryCode as Country['code']}
              onCountryChange={onCountryChange}
              mode="iconOnly"
            />

            <Box display="flex" alignItems="center">
              <Icon>person</Icon>&nbsp;
              <Text color="inherit" component="h1" variant="h6">
                {userData?.username}
              </Text>
            </Box>

            <Box marginBottom={2}>
              <Text color="inherit" variant="subtitle1">
                {userData?.organizationId}
              </Text>
            </Box>

            <Box color="black">
              <SignoutButton
                size="small"
                color="primary"
                startIcon={<Icon>exit_to_app</Icon>}
                onClick={() => (window.location.href = SSO_LOGOUT_URL)}
              >
                {translate('logout')}
              </SignoutButton>
            </Box>
          </Box>
        )}
      </HomeLayout>

      <Version>
        Version: <strong>{TAG_NAME}</strong>
      </Version>

      <BackgroundImage src={`${MEDIA_SERVER}/bg.jpg`} alt="DC3" />
    </>
  );
};

export default Index;
