import React from 'react';

import { StyledAppTile, TileIcon, TileLabel } from './AppTile.styles';

export const AppTile: React.VFC<{
  text: string;
  icon: string;
  dimension?: number;
  url: string;
  background?: string;
  description: string;
}> = ({ icon, text, dimension, url, background, description }) => (
  <StyledAppTile
    title={description}
    href={url}
    dimension={dimension}
    background={background}
  >
    <TileIcon fontSize="large">{icon}</TileIcon>
    <TileLabel>{text}</TileLabel>
  </StyledAppTile>
);
