import { ThemeProps } from '@dc3/utils/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '@mui/material';
import { Icon } from '@dc3/ui/icon';

type StyledAppTileProps = ThemeProps & {
  dimension?: number;
  background?: string;
};

const AppTileCSS = ({ dimension = 100 }: StyledAppTileProps) => css`
  width: ${dimension}px;
  height: ${dimension}px;
  background: rgba(0, 0, 0, 0.4);

  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
  }

  &:not(:first-of-type) {
    margin-left: 8px;
  }

  :visited,
  :hover,
  :focus {
    text-decoration: none;

    color: white;
  }
`;

export const StyledAppTile = styled(Button)`
  ${AppTileCSS}
`;

export const TileIcon = styled(Icon)`
  // remove important once this is fixed https://kramphub.atlassian.net/browse/DC3-1784
  font-size: 56px !important;
`;

export const TileLabel = styled.span`
  text-transform: none;
  text-align: center;
`;
