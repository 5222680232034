import * as Types from '../../../../libs/data-access/graphql/src/lib/generated-types/gql.model';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GQLDc3_GetProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GQLDc3_GetProfileQuery = { __typename?: 'Query', profile: { __typename?: 'AccountProfile', settings: Array<{ __typename?: 'MapValue', key: string, value: string }> } };


export const Dc3_GetProfileDocument = gql`
    query dc3_getProfile {
  profile {
    settings {
      key
      value
    }
  }
}
    `;

/**
 * __useDc3_GetProfileQuery__
 *
 * To run a query within a React component, call `useDc3_GetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDc3_GetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDc3_GetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useDc3_GetProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GQLDc3_GetProfileQuery, GQLDc3_GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GQLDc3_GetProfileQuery, GQLDc3_GetProfileQueryVariables>(Dc3_GetProfileDocument, options);
      }
export function useDc3_GetProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GQLDc3_GetProfileQuery, GQLDc3_GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GQLDc3_GetProfileQuery, GQLDc3_GetProfileQueryVariables>(Dc3_GetProfileDocument, options);
        }
export type Dc3_GetProfileQueryHookResult = ReturnType<typeof useDc3_GetProfileQuery>;
export type Dc3_GetProfileLazyQueryHookResult = ReturnType<typeof useDc3_GetProfileLazyQuery>;
export type Dc3_GetProfileQueryResult = Apollo.QueryResult<GQLDc3_GetProfileQuery, GQLDc3_GetProfileQueryVariables>;